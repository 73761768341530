<template>
    <div class="service-card-link">
        <div class="service-card__wrap">
            <div class="service-card__title main-title">{{card__title}}</div>
            <div class="service-card__desc">{{card__desc}}</div>
        </div>
        <div class="sevice-card__button-wrap">
            <div class="service-card__button"><router-link to="/services">Перейти к услугам</router-link></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'ServiceCard',
    props:['card__title', 'card__subtitle', 'card__desc']
}
</script>

<style>
.service-card-link{
    background: var(--back-light); 
    transition: all .5s;
    overflow: hidden;
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: var(--box-shadow);
    position: relative;
}
.service-card-link:hover{
    transform: scale(1.05);
    box-shadow: var(--box-shadow-hover);
}
.service-card-link:hover > .sevice-card__button-wrap{
    bottom: 0;
    cursor: pointer;
    border-top:1px dotted rgb(216, 216, 216);
}
.service-card-link:hover > .service-card__wrap{
    opacity: .2;
    cursor: pointer;
}
.sevice-card__button-wrap{
    position: absolute;
    bottom: -20%;
    left:0;
    right: 0;
    text-align: center;
    height: 20%;
    background: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: bottom .7s;
    color: var(--text-dark);
}
.service-card__button a{
    color: black;
}
.service-card__wrap{
    text-align: center;
    max-width: 21vw;
    height: 100%;
}
.service-card__title{
    padding:0 0 1rem 0;
    border-bottom: 1px solid rgb(217, 217, 217);
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}
.service-card__desc{
    text-align: left;
    padding: 1rem 0 0;
    font-size: 1.1rem;
}

@media (max-width:640px) {
    .service-block{
     flex-wrap: wrap;
    }
    .service-card-link{
        margin: 10px 0;
    }
    .service-card__wrap{
      max-width: 100%;
    }
}
</style>