<template>
    <div class="main-stepper">
      <div class="stepper-wrap">
          <ol class="c-stepper">
            <li class="c-stepper__item c-stepper__item--1" @click="textNum(textWrap.one),stepperActive($event)">
              <span class="c-stepper__title">КОНФИДЕНЦИАЛЬНОСТЬ</span>
            </li>
            <li class="c-stepper__item c-stepper__item--2" @click="textNum(textWrap.two),stepperActive($event)">
              <span class="c-stepper__title">24/7</span>
            </li>
            <li class="c-stepper__item c-stepper__item--3" @click="textNum(textWrap.three),stepperActive($event)">
              <span class="c-stepper__title">АКТУАЛЬНОСТЬ</span>
            </li>
          </ol>
      </div>
      <div class="stepper-text">
            <div class="stepper-text__wrap">
                <h3 class="stepper-text__title">{{textTitle}}</h3>
                <p class="stepper-text__text">{{textDesc}}</p>
            </div>
      </div>      
    </div>
</template>

<script>
export default {
name:'WhyMe',
data(){
  return{
      textWrap:{
        one:{
            bool:true,
            title:'Гарантия конфиденциальности',
            desc:'при консультировании, составлении правовых документов, представлении интересов и оказании любых видов юридической помощи, даю расписку о соблюдении режима конфиденциальности, в случае нарушения которой, мной будет выплачена компенсация'
        },
        two:{
            bool:false,
            title:'Правовая помощь трудового юриста в любое время ',
            desc:'звоните и пишите в мессенджеры, на электронную почту и т.д. в любое время, я окажу помощь, как только увижу ваш месседж (сообщение или вызов)'
        },
        three:{
            bool:false,
            title:'Применение только актуальной судебной практики',
            desc:'все профессиональные выводы, доводы, прогнозы делаю исключительно руководствуясь актуальной судебной практикой. Причем я обязательно ознакомлюсь с судебной практикой именно по нужному «кассационному округу».'
        },
      },
      textTitle:``,
      textDesc:``,
  }   
},
methods:{
  textNum(i){
    this.textTitle = i.title;
    this.textDesc = i.desc;
  },
  stepperActive(event){
    document.querySelectorAll('.c-stepper__item').forEach(function(item){
      item.classList.remove('c-stepper__item--bold');
    })
    event.target.classList.add('c-stepper__item--bold');
  }
},
mounted(){
  this.textTitle = this.textWrap.one.title;
  this.textDesc = this.textWrap.one.desc;
  document.querySelector('.c-stepper__item--1').classList.add('c-stepper__item--bold');
}
}
</script>



<style>
:root {
  --circle-size: clamp(5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.main-stepper{
  background:var(--back-light);
  padding: 3rem 0;
}

.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  transition: transform .5s;
  cursor: pointer;
}

.c-stepper__item:hover{
  transform: scale(1.03);
}

.c-stepper__item--1:before{
  content: '';
  background: url(@/assets/img/spy.png) no-repeat;
  background-size: 60%;
  background-position: center center;
  }

.c-stepper__item--2:before{
  content: '';
  background: url(@/assets/img/timeless.png) no-repeat;
  background-size: 60%;
  background-position: center center;
}

.c-stepper__item--3:before{
  content: '';
  background: url(@/assets/img/relevant.png) no-repeat;
  background-size: 60%;
  background-position: center center;
}

.c-stepper__item:before {
  --size: 3rem;
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  border:1px solid var(--text-dark);  
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  opacity: .7;
}

.c-stepper__item--bold:before {
  --size: 3rem;
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  border:1px solid var(--text-dark);  
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5) inset;
}

.c-stepper__item--bold{
  font-weight: 700;
}
.c-stepper__title {
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
}


.stepper-wrap {
  margin: auto;
  width: 90%;
}

.stepper-wrap ol{
  padding: 0;
}

.stepper-text{
  border: 1px inset rgba(123, 115, 151, 0.3);
  border-radius: 3px;
  width: 80%;
  margin: auto;
}

.stepper-text__title{
  padding: 2rem 2rem 0;
  font-size: 1.2rem;
  font-weight: 400;
}

.stepper-text__text{
  padding: 1rem 2rem;
  font-size: 1.1rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@media (max-width:640px) {
  .stepper-wrap{
    width: 100%;
  }
  .c-stepper__title{
    display: none;
  }
  .stepper-text{
    width: 90%;
  }
  .stepper-text__text{
    padding: 1rem;
    margin: 0;
  }
}
</style>