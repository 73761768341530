<template>
  <div class="main-baner">
    <modal-form v-model:show="show"/>
    <MainSlide/>
    <main-form @showModal="showModalForm"></main-form>
  </div>
</template>

<script>

import MainForm from "./MainBaner/Main-Form.vue"
import MainSlide from "./MainBaner/MainSlide.vue"
import ModalForm from './ModalForm.vue'


export default {
  name:'MainBaner',
  components:{
     MainForm,
     MainSlide,
     ModalForm,
  },
  data(){
    return{
      show:false,
    }
  },
  methods: {
      showModalForm: function () {
          this.show = true;
          document.body.style.overflow='hidden'
      },
  },
}  
</script>

<style>
.main-baner{
    padding: 15vh 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30;
}
</style>
