<template>
    <div class="nav-bar">
      <div class="nav-bar__logo">
          <transition appear name="slide-fade">
            <router-link to="/" class="logo">
              <img src="img/logo.svg" alt="">
            </router-link>
          </transition>
      </div> 
      <div class="nav-bar-menu">
        <transition appear name="slide-toleft" @click="closeMobilMenu">
          <div class="mobile-menu-icon">
            <i class="fa-solid fa-bars"></i>
          </div>
        </transition>
        <transition appear name="slide-toleft">
          <ul class="nav-bar-menu__items">          
            <li class="nav-bar-menu__item" v-for="item in navBarMenu" :key="item">
                <router-link :to="item.link">{{item.name}}</router-link>
            </li>            
          </ul>
        </transition>
      </div>
      <transition 
        enter-active-class="animate__animated animate__fadeInTopRight"
        leave-active-class="animate__animated animate__fadeOutTopRight"
      >
        <div v-if="mobileMenuBar" class="mobile-menu-bar">
          <div class="close-mobile-menu" @click="closeMobilMenu">
            <i class="fa-solid fa-close"></i>
          </div>
          <ul class="mobile-menu__items">          
              <li @click="closeMobilMenu" class="mobile-menu__item" v-for="item in navBarMenu" :key="item">
                <router-link :to="item.link">{{item.name}}</router-link>
              </li>            
            </ul>
        </div>
      </transition>
    </div>  
</template>

<script>
export default {
    name:'NavBar',
    data(){
        return{
          mobileMenuBar:false,
          navBarMenu:[
          {
            name:'Главная',
            link:'/'
          },
          {
            name:'Опыт',
            link: '/about'
          },
          {
            name:'Услуги',
            link:'/services'},
          // {
          //   name:'Партнеры',
          //   link:'/partners'}
          ] 
        }
    },
    methods:{
      closeMobilMenu(){
        this.mobileMenuBar=!this.mobileMenuBar;
        if(this.mobileMenuBar){
          document.body.classList.add('overflow-main')
        }else{document.body.classList.remove('overflow-main')}
      }
    }
}
</script>

<style>


.nav-bar{
    padding:0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    position: absolute;
    top: 5vh;
    left: 0;
    right: 0;
}
/* Меню */
.mobile-menu-icon{
  display: none;
}
.mobile-menu-bar{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(245, 245, 245, 0.99);
  padding: 2rem;
  z-index: 2;
}
.mobile-menu__items{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding:1rem 10vh;
  height: 70%;
  width: 80%;
}
.mobile-menu__item{
  color: black;
  list-style: none;
  z-index: 3;
  font-size: 1.7rem;
}
.nav-bar-menu{
  flex-basis: 40%;
}
.nav-bar-menu__items{
  display: flex;
  justify-content: space-between;
}

.nav-bar-menu__item{
  padding: 0 5px 0;
  list-style: none;
  text-transform: uppercase;
}
/* Анимация меню */
.slide-toleft-enter-active {
  transition: all .5s ease-out .3s;
}

.slide-toleft-enter-from,
.slide-toleft-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.nav-bar-menu__item{
  font-weight: 500;
  font-size: 1rem;
  transition: transform .3s;
}

.nav-bar-menu__item:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.router-link-active{
  font-weight: bold;
}
.nav-bar-menu__item::after{
  content: "";
  bottom: 0;
  
}

/* Logo */
.nav-bar__logo{
  flex-basis: 50%;
}

.logo img{
  object-fit: contain;
  height: 2rem;
  display: flex;
  align-items: center;
}

@media (prefers-color-scheme: dark) {
  .logo{
    color: var(--logo-light);
  }
}
/* Анимация логотипа */
.slide-fade-enter-active {
  transition: all .5s ease-out .3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-150px);
  opacity: 0;
}

@media (max-width:640px){
  .nav-bar-menu{
    display: flex;
    justify-content: flex-end;
  }
  .nav-bar-menu__items{
    display: none;
  }
  .mobile-menu-icon{
    display: flex;
  }
  .mobile-menu-icon svg{
    height: 24px;
    width: 24px;
  }
  .mobile-menu__item a{
    font-size: 2rem;
    color: black;
  }
  .nav-bar{
    padding: 0;
  }
  .animate__animated.animate__fadeInTopRight, .animate__animated.animate__fadeOutTopRight{
    animation-duration: 300ms;
  }
  .close-mobile-menu{
  font-size:2.5rem;
  width: 100%;
  text-align: right;
  padding: 1rem 1rem 2rem;
}
}
</style>