<template>
  <div class="top-screen">
    <MainBaner/>
  </div>
</template>

<script>

import MainBaner from './Main-baner.vue'


export default {
    name:'TopScreen',
  components: {
    MainBaner,
}

}
</script>

<style>
.top-screen{
  background:var(--back-light), url(@/assets/img/fon-main-screen-p.png) no-repeat;
  background-size: cover;
  background-position: top right;
  height: 100vh;
}
@media (max-width:640px) {
  .top-screen{
    height: auto;
  }
}
</style>