<template>
  <NavBar/>
  <TopBar/>
  <router-view/>

</template>

<script>
import NavBar from './components/MainBar/Nav-Bar.vue'
import TopBar from './components/MainBar/Top-Bar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    TopBar
}
}
</script>

<style>


</style>
