<template>
<div class="map-contact">
    <div class="contact-block">
        <address>ул. Адмирала Юмашева, 4В, Севастополь отдельный вход со двора (со стороны ПУДа, Лидера), офис 3, этаж 1</address>
        <div class="contact-tel"><a href="tel:+79787045157">+7 (978) 704-51-57</a></div>
        <a class="contact-link" v-for="(item, index) in socialIcon" :key="index" :href="item.href">
            <font-awesome-icon :icon="item.link" />
        </a>
    </div>
    <div class="map-block">
        <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=207799613029" width="100%" height="100%" frameborder="0"></iframe>
    </div>
</div>
</template>
  
<script>
export default {
    data(){
        return{
           socialIcon:{
                whatsapp:{
                    href:"https://wa.me/79787045157",
                    link:"fa-brands fa-whatsapp"
                },
                telegram:{
                    href:"https://t.me/havanka",
                    link:"fa-brands fa-telegram",
                },
                viber:{
                    href:"viber://chat?number=79787045157",
                    link:"fa-brands fa-viber"
                },
                mail:{
                    href:"mailto:i@eryabova.ru",
                    link:"fa-solid fa-envelope"
                }
            }
        }
    }
}
</script>
  
<style>
.map-contact {
    display: flex;
    padding: 5%;
    min-height: 460px;
}
.contact-block {
    flex-basis: 50%;
    margin: auto;
    align-self: center;
    padding: 1rem;
}
address{
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    line-height: normal;
    max-width: 540px;
}
.contact-tel {
    font-size: clamp(1rem, 2.5vw, 3rem);
    padding: 3rem 0;
}
.contact-link svg{
    margin-right: 1rem;
    font-size: clamp(1rem, 3vw, 3.5rem);
    transition: transform .5s;
}
.contact-link:hover svg{
    transform: scale(1.05);
}
.map-block {
    filter:grayscale(60%);
    transition: filter .5s;
    flex-basis: 40%;
}
.map-block:hover {
    filter:grayscale(0%);
}

@media (max-width:960px) {
    .map-contact {
    display: flex;
    flex-direction: column;
    padding: 5%;
    min-height: 460px;
}
.map-block, .contact-block {
    flex-basis: 100%;
}
}
</style>