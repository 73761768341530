<template>
    <div class="main-baner__form-wrap">
        <div class="form-header">Онлайн-консультация по вопросам трудового права</div>
        <div class="main-baner__form-btn" @click="$emit('showModal')">ПЕРЕЙТИ К КОНСУЛЬТАЦИИ</div>
    </div>
</template>

<script>

export default {
    name:'MainForm',
}
</script>

<style>
.main-baner__form-wrap{
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: var(--box-shadow);
    background: var(--back-light-block); 
    padding:1.5rem 2rem; 
    height: fit-content;
    margin: 0;
    width: 80vw;
    min-width: 400px;
    backdrop-filter: blur(3px); 
    mix-blend-mode: multiply;
    backdrop-filter: blur(1px);
    margin: auto;
}

.form-header{
    font-size: 1.3rem;
    text-transform: uppercase;
    text-align: center;
}
.main-baner__form-btn{
    margin:0;
    padding: 0.5rem 1rem;
    outline: none;
    border: 1px solid var(--logo-light);
    min-height: 2rem;
    width: fit-content;
    background-color: rgb(243, 243, 243, .9) ;
    mix-blend-mode: multiply;
    transition: box-shadow .3s, background-color .3s;
    font-weight: 500;
    font-size: 1.1rem;
    transition: 1s;
    color: var(--logo-light);
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-baner__form-btn:hover{
    color:var(--button-background);
    background-color: rgba(71, 33, 33, 0.3);
    transform: scale(1.03);
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

@media (max-width:640px){
    .main-baner__form-wrap{
        flex-direction: column;
        min-width: 100%;
    }  
    .form-header{
    padding:0 0 1rem;
    font-size: 1.2rem;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    }
}
</style>