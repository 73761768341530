import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import( '../views/ServicesView.vue')
  },
  {
    path: '/oplata',
    name: 'oplata',
    component: () => import('../views/OplataView.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('../views/PartnersView.vue')
  },
  {
    path: '/MainForm',
    name: 'mainform',
    component: () => import('../views/MainForm.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})


export default router
