<template>
    <div class="top-bar">       
        <div class="top-bar__phone">        
        <a href="tel:+79787045157">
           <font-awesome-icon icon="fas fa-phone" /> +7(978) 704-51-57
        </a>
        </div>
        <div class="top-bar__social" @click="toggleMobileBar">
            <i class="fa-solid fa-comment mobile-social" ></i> 
            <a class="desctop-social" v-for="(item, index) in socialIcon" :key="index" :href="item.href">
                <font-awesome-icon :icon="item.link"/>
            </a>
        </div>
        <transition 
            enter-active-class="animate__animated animate__slideInDown"
            leave-active-class="animate__animated animate__slideOutUp">
            <div v-if="mobilBar" class="social-mobile-bar">
                <a class="mobile-social" v-for="(item, index) in socialIcon" :key="index" :href="item.href">
                    <font-awesome-icon :icon="item.link" />
                </a>
                <div class="close-mobile-bar" @click="toggleMobileBar">
                    <i class="fa-solid fa-close mobile-social"></i>
                </div>
            </div>
        </transition>
    </div>  
</template>

<script>
export default {
    name:'TopBar',
    data(){
        return{ 
            mobilBar:false,          
            socialIcon:{
                whatsapp:{
                    href:"https://wa.me/79787045157",
                    link:"fa-brands fa-whatsapp"
                },
                telegram:{
                    href:"https://t.me/havanka",
                    link:"fa-brands fa-telegram",
                },
                viber:{
                    href:"viber://chat?number=79787045157",
                    link:"fa-brands fa-viber"
                },
                mail:{
                    href:"mailto:i@eryabova.ru",
                    link:"fa-solid fa-envelope"
                }
            }
        }
    },
    methods:{
        toggleMobileBar(){
            this.mobilBar = !this.mobilBar;
        }
    }
}
</script>

<style>
a{
    text-decoration: none;
    color: var(--text-dark);
}
.top-bar{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    border-bottom: 1px solid rgba(131, 131, 131, 0.1);
    font-size: 1.3vw;
    height: 5vh;
}
.top-bar__phone{
    font-weight: 200;
    color: rgba(0, 0, 0, 0.7);
    padding: 0 5px;
    text-align: right;
    transition: transform .3s ease-in;
}
.top-bar__phone:hover{
  transform: scale(1.1);
}
.top-bar__phone a{
    color: rgba(0, 0, 0, 0.7);
}
.top-bar__social{
    display: flex;
}
.mobile-social{
    display: none;
}
.top-bar__social a {
    padding-left: 10px;
    transition: transform .3s;
    color: rgba(0, 0, 0, 0.7);
}

.top-bar__social a:hover{
    transform: scale(1.1);
    color: rgba(0, 0, 0, 0.9);
}
.desctop-social svg{
    font-size: 1.2rem;
}
.social-mobile-bar{
    display: none;
}
@media (max-width:640px) {
    .top-bar{
        padding:0 15px;
    }
    .top-bar__phone{
        font-size: 1.1rem;
    }
    .social-mobile-bar{
        display: flex;
        justify-content: space-around;
        position: fixed;
        align-items: end;
        width: 100%;
        height: 50px;
        background: whitesmoke;
    }
    .desctop-social{
        display: none;
    }
    .mobile-social{
        display: block;
    }
    .mobile-social svg{
        opacity: 1;
        color: black;
    }
    .mobile-social{
        font-size: 1.5rem;
    }
    .animate__animated.animate__slideInDown, .animate__animated.animate__slideOutUp {
        animation-duration: 500ms;
    }
}
</style>