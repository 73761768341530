<template>
    <div class="main-about">
        <h2 class="main-about__title animate__animated animate__bounce">
            О себе
        </h2>
        <div class="subtitle-inn">
            <h2 class="main-banner__subtitle ">
                <span class="main-banner__surname">Рябова</span> Евгения Игоревна
            </h2>
            <span class="inn-modal-link"> *</span>
            <h3 class="subtitle-second">Кандидат наук</h3>
            <div class="inn-modal">
                <p>Является плательщиком налога на профессиональный доход (ИНН 772857290413)</p>
            </div>
        </div>
        <p class="main-about__desc">Юрист, кандидат наук, специалист в отрасли трудового права. Юридический стаж более 20 лет, 2000 судебных заседаний в «топовых» судах Москвы и ЮФО (Краснодар, Симферополь, Севастополь).
        </p>
        <div class="about-btn"><router-link to="about">Подробнее</router-link></div>
    </div>
</template>

<script>
export default {
    name:"MainAbout",
}
</script>

<style>
/* MAIN ABOUT */
.main-about{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    flex-basis: 65%;
    padding: 3rem 0;
}
.main-about__title{
    font-size: 1.7rem;
    color:var(--text-dark);
    font-weight: 500;
    padding: 0 0 3rem;
    text-transform: uppercase;
    text-align: center;
}
.subtitle-inn{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inn-modal{
    position: absolute;
    left: 10%;
    right: 10%;
    top: -50%;
    font-size: 1rem;
    font-style: italic;
    text-align: center;
    border: white 2px unset;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    box-shadow: 1px 1px 3px rgba(72, 69, 69, 0.313);
    display: none;
}

.inn-modal-link:hover ~ .inn-modal{
    display: block;
}

.main-banner__subtitle{
    font-weight: 300;
    font-size: 2rem;
    line-height: .8;
    padding: 1rem 0;
}
.inn-modal-link{
    font-size: 1.4rem;
    font-weight: 200; 
}
.subtitle-second{
    flex-basis: 100%;
    text-align: center;
    font-size: 1rem;
}
.main-about__desc{
    font-size: 1.1rem;
    margin: 0; 
    padding:2rem;
    line-height: 1.5;
}
.about-btn{
    box-sizing: border-box;
    margin: auto;
    font-size: 1.5rem;
    font-weight: 200;
    width: fit-content;
    padding:10px;
    border-right:1px inset rgba(0, 0, 0, 0.2);
    border-bottom:1px inset rgba(0, 0, 0, 0.2);
    border-top:1px inset rgba(255, 255, 255, 0.1);
    border-left:1px inset rgba(255, 255, 255, 0.1);
    transition: .7s;
}
.about-btn a{
    color: var(--text-dark); 
}
.about-btn:hover{
    background: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    box-shadow: 0 0 20px rgb(220, 220, 220, .3);
}
.about-btn:hover a{
    color: var(--logo-light);
    transition: .7s; 
}
@media (max-width:640px) {
    .main-banner__subtitle{
        text-align: center;
        line-height: normal;
        font-size: 1.5rem;
    }
    .main-banner__surname{
        display: inline-block;
        width: 100%;
        font-size: 2rem;
    }
    .inn-modal-link{
        display: flex;
        align-items: center;
    }
    .main-banner__subtitle{
        flex-basis: 95%;
        padding-left: 5%;
    }
    .main-about__desc{
        padding: 2rem 0;
        font-size: 1.2rem;
    }
}
</style>