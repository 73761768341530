<template>
  <footer>
    <nav class="footer-menu">
        <ul class="footer-menu__items">            
              <li class="nav-bar-menu__item" v-for="item in navBarMenu" :key="item">
                <router-link :to="item.link">{{item.name}}</router-link>
              </li>             
        </ul>
    </nav>
    <div class="line-footer"></div>
    <div class="footer-bottom">
        <div class="top-bar__phone footer-phone">       
            <a href="tel:+79787045157">
                <font-awesome-icon icon="fas fa-phone" /> +7(978) 704-51-57
            </a>
        </div>
        <div class="footer__logo">Трудовой юрист</div>
        <div class="footer-social">
        <a v-for="(item, index) in socialIcon" :key="index" :href="item.href">
            <font-awesome-icon :icon="item.link" />
        </a>
    </div>
    </div>
    <div class="line-footer"></div>
    <div class="footer-legacy">
        <p><i>регистрационный номер в реестре операторов, осуществляющих обработку персональных данных 77-24-156587</i></p>
        <big>&copy; Рябова Евгения Игоревна, 2024</big>
    </div>
  </footer>
</template>

<script>
export default {
    name:'TheFooter',
    data(){
        return{
           navBarMenu:[
          {
            name:'Главная',
            link:'/'
          },
          {
            name:'Опыт',
            link: '/about'
          },
          {
            name:'Услуги',
            link:'/services'},
        //   {
        //     name:'Партнеры',
        //     link:'partners'},
          {
            name:'Реквизиты',
            link:'/oplata'
          }
          ] ,
           socialIcon:{
                whatsapp:{
                    href:"https://wa.me/79787045157",
                    link:"fa-brands fa-whatsapp"
                },
                telegram:{
                    href:"https://t.me/havanka",
                    link:"fa-brands fa-telegram",
                },
                viber:{
                    href:"viber://chat?number=79787045157",
                    link:"fa-brands fa-viber"
                },
                mail:{
                    href:"mailto:i@eryabova.ru",
                    link:"fa-solid fa-envelope"
                }
            }
        }
    }
}
</script>

<style>
.footer-menu{
    width: 60%;
    margin: auto;
    padding: 1rem;
}
.footer-menu__items{
    display: flex;
    justify-content: space-around;
}
.footer-menu__items a{
    color: black;
}
.footer-bottom{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}
.footer-phone{
    flex-basis: 20%;
}
.footer__logo{
    text-align: center;
    font-size: 1rem;
    font-family: 'Stalinist One', sans-serif;
    flex-basis: 60%;
}
.footer-social{
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    flex-basis: 20%;
}
.line-footer{
    width: 100%;
    height: 3px;
    border-bottom: 1px inset rgba(0, 0, 0, 0.1);
    margin: 1rem auto;
}
.footer-link{
    display: flex;
    justify-content: center;
}
.footer-link a{
    padding: 0 1rem 1rem;
}
.footer-legacy{
    text-align: center;
}
@media (max-width:640px) {
    .footer-bottom{
    flex-direction: column;
}
.footer-bottom div{
    padding: 10px;
}
.footer__logo{
    order: 2;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Roboto Slab' , sans-serif;
    text-transform: uppercase;
}
.footer-social a{
    padding: 10px;
    font-size: 2rem;
    color: black;
}
}
</style>