<template>
  <div class="bottom-form">
    <span class="bottom-form__title">Оставьте заявку</span>
    <div class="bottom-form__wrap">
        <form class="bottom-form-input__wrap">
            <input type="tel" class="bottom-form__tel telnum" name="tel" placeholder="Телефон">
            <input type="submit" class="bottom-form__btn" name="btn" value="ОТПРАВИТЬ">
        </form>
        <span class="legal-info">Нажимая ОТПРАВИТЬ вы соглашаетесь с <a href="legal.pdf" target="blank">политикой конфиденциальности</a></span>
    </div>
  </div>
</template>

<script>
export default {
  name:'BottomForm',
  mounted() {
    // слушатель на кнопку
  this.$nextTick(function () {
    document.querySelectorAll('.bottom-form__btn').forEach(item=>{
  item.addEventListener('click', function (e) {
    e.preventDefault();
    let form = this.form;
    let tel = form.querySelector('.bottom-form__tel').value;
    if (tel) {
      // sendMail(form);
      sendTelegram(form);
    } else {
      alert('Заполните поля формы')
    }
    this.form.reset();
    alert('Сообщение отправлено')
  })
})
  async function sendTelegram(form) {
    await fetch("https://utrud.ru/telegram.php", {
      method: "POST",
      body: new FormData(form),
    });
  }
  })
  // маска телефон
  window.addEventListener("DOMContentLoaded", function () {
  [].querySelectorAll('.telnum'), function (input) {
    var keyCode;

    function mask(event) {
      event.keyCode && (keyCode = event.keyCode);
      var pos = this.selectionStart;
      if (pos < 3) event.preventDefault();
      var matrix = "+7 (___) ___ ____",
        i = 0,
        def = matrix.replace(/\D/g, ""),
        val = this.value.replace(/\D/g, ""),
        new_value = matrix.replace(/[_\d]/g, function (a) {
          return i < val.length ? val.charAt(i++) || def.charAt(i) : a
        });
      i = new_value.indexOf("_");
      if (i != -1) {
        i < 5 && (i = 3);
        new_value = new_value.slice(0, i)
      }
      var reg = matrix.substr(0, this.value.length).replace(/_+/g,
        function (a) {
          return "\\d{1," + a.length + "}"
        }).replace(/[+()]/g, "\\$&");
      reg = new RegExp("^" + reg + "$");
      if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) this.value = new_value;
      if (event.type == "blur" && this.value.length < 5) this.value = ""
    }

    input.addEventListener("input", mask, false);
    input.addEventListener("focus", mask, false);
    input.addEventListener("blur", mask, false);
    input.addEventListener("keydown", mask, false)

  };
});
}
}
</script>

<style>
.bottom-form{
  margin: 1rem 0;
  background: white;
  box-shadow: var(--box-shadow);
  padding: 0 0 1rem;
}
.bottom-form__title{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 300;
}
.bottom-form__wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottom-form-input__wrap{
  display: flex;
  width: 100%;
  justify-content: center;
}
.bottom-form-input__wrap input{
  height: 2.5rem;
  width: 50%;
  max-width: 300px;
  margin: 0 1rem;
  outline: none;
  border: none;
  box-shadow: var(--box-shadow);
  background: whitesmoke;
  transition: box-shadow 1s, color 1s, background-color 1s;
  font-family: 'Montserrat', sans-serif;
}
.bottom-form-input__wrap input::placeholder{
  padding-left: 1rem;
}
.bottom-form__btn:hover{
  cursor: pointer;
  box-shadow: 1px 1px 10px rgb(205, 205, 205);
  /* transform: scale(1.05); */
  background: var(--button-background);
  color: whitesmoke;
}
.legal-info{
  font-size: 12px;
  padding: 1rem;
}
.legal-info a{
 font-style: italic;
 color: rgb(23, 48, 91);
}
@media (max-width:640px) {
  .bottom-form-input__wrap{
    flex-direction: column;
    align-items: center;
  }
  .bottom-form-input__wrap input{
    width: 100%;
    margin-top: 10px;
  }
  .legal-info{
    padding:1rem 2rem;
  }
}
</style>