<template>
  <div class="home">
    <TopScreen/>
    <WhyMe/>
    <div class="line-about"></div>
    <ServiceBlock/>
    <div class="line-about"></div>
    <MainAbout/>
    <div class="line-about"></div>
    <OtzivSwiper/>
    <BottomForm/>
    <MapContact/>
    <TheFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopScreen from '../components/Top-screen.vue'
import ServiceBlock from '../components/ServiceCardsBlock.vue'
import MainAbout from '../components/Main-About.vue'
import OtzivSwiper from '../components/Swiper-Slide.vue'
import BottomForm from '../components/Bottom-Form.vue'
import TheFooter from '../components/TheFooter.vue'
import WhyMe from '../components/WhyMe.vue'
import MapContact from '../components/MapContact.vue'

export default {
  name: 'HomeView',
  components: {
    TopScreen,
    ServiceBlock,
    MainAbout,
    WhyMe,
    OtzivSwiper,
    BottomForm,
    TheFooter,
    MapContact
  }
}
</script>
