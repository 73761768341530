<template>
  <div class="main-slide">
    <transition appear name="main-slide-h1">
        <div class="main-slide_title">
          <h1>ТРУДОВОЙ ЮРИСТ</h1>
        </div>
    </transition>
    <transition-group appear name="main-slide-ul" tag="ul" class="main-slide__ul">
    <li v-for="item in liList" :key="item">
      {{ item }}
    </li>
  </transition-group>
  </div>  
</template>

<script>
export default {
    name:"MainSlide",
    data(){
        return{
            liList:[
                'консультация, сопровождение увольнения, переговоры, судебное представительство, составление документов'
            ]
        }
    }
}
</script>

<style>
.main-slide{
  min-height: 60vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: 300;
    padding: 5vh 0;
}
.main-slide h1{
    font-size: clamp(24px, 4rem, 5vw);
    padding: 10vh 0 5vh;
    font-weight: 700;
    mix-blend-mode:multiply;
    color: rgba(100, 0, 0, .8);
}

.main-slide__ul{
  max-width: 700px;
  line-height: clamp(14px, 1.4rem, 2vw);
}

.main-slide__ul > li{
    font-size: clamp(14px, 1.4rem, 2vw);
    font-weight: 200;
    font-family: 'Roboto Slab', serif;
    list-style: none;
    padding-inline-start:0;
    color: rgba(50, 23, 23, 0.9);
    background:linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%), url(@/assets/img/paper.png);
    margin: 2px 0;
    padding: 10px;
    display: inline-block;
    mix-blend-mode:multiply;
}
.chert{
    font-size: 1rem;
}

/* Анимация заголовка */
.main-slide-h1-enter-active {
  transition: all .3s ease-out .5s;
}

.main-slide-h1-enter-from,
.main-slide-h1-leave-to {
  transform: translateY(150px);
  opacity: 0;
}
/* Анимация списка */
.main-slide-ul-enter-active{
   transition: all .5s ease-out .8s; 
}

.main-slide-ul-enter-from {
  opacity: 0;
}

@media (max-width:640px) {
  .main-slide_title h1{
    font-size: clamp(24px, 4rem, 15vw);
    line-height: normal;
    padding: 0;
  }
  .main-slide__ul li{
    font-size:1.5rem ;
    line-height: 1;
  }
}
</style>