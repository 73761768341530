<template>
  <div class="otziv-swiper">
    <swiper
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    loop
    :autoplay="{
      delay: 5000,
    }"
    >
      <swiper-slide v-for="item in otziv" :key="item" >
        <div class="swiper-slide__wrap">
          <a :href="item.swiperLink" target="_blank"><img class="slide-img" :src="item.swiperImg" alt=""></a>
          <span class="slider-name">{{item.swiperName}}</span>
          <span class="slide-desc"><q>{{item.swiperDesc}}</q></span>
          <span class="slide-title">{{item.swiperTitle}}</span>
          <a :href="item.swiperLink" class="slide-link" target="blank">Перерейти в источник</a>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  // import Swiper core and required modules
  import SwiperCore, { Autoplay, Navigation, Pagination} from 'swiper';
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide} from 'swiper/vue';
  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  SwiperCore.use([Autoplay, Navigation, Pagination])
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
     data(){
      return{
        otziv:{
          slide1:{
            swiperTitle:'Отзыв на платформе Harant.ru',
            swiperName:'Елена',
            swiperImg:require('../assets/img/logo-harant.svg'),
            swiperDesc:'Евгения выполнила профессиональную консультацию, касающуюся трудового спора, ответила на все имеющиеся вопросы. Помогла подготовиться к разговору с работодателем, подсказала на чем заострить внимание, что свидетельствует о высоком профессионализме и большом опыте работы. До общения с Евгенией не знала с какой стороны подступится к проблеме и пребывала в растерянности. Спасибо большое за помощь!',
            swiperLink:'https://harant.ru/lawyers/sevastopol/ryabova-evgeniya-igorevna/',
          },
          slideOne:{
            swiperTitle:'Отзыв на платформе Яндекс.Услуги',
            swiperName:'Александр Андреевич Захарчев',
            swiperImg:require('../../public/img/yandexUslugi.svg'),
            swiperDesc:`Прекрасная профессионал своей области!
                Помогла решить нестандартную проблему со сложным упёртым работодателем. Специалист готов был подолгу консультировать по вопросам, доходчиво и понятно объяснял ситуацию, законодательство, правоприменительную практику и возможные ...`,
            swiperLink:'https://uslugi.yandex.ru/profile/EvgeniyaRyabova-335776?msp=no&occupationId=%2Furisty&specId=%2Furisty%2Ftrudovye-spory',
          },
          slideTwo:{
            swiperTitle:'Отзыв на платформе Яндекс.Услуги',
            swiperName:'Группа Ю.',
            swiperImg:require('../../public/img/yandexUslugi.svg'),
            swiperDesc:'Евгения Игоревна, позвольте выразить благодарность за оказанную качественную своевременную юридическую помощь! Желаю Вам исполнения всего задуманного и желаемого! Рекомендую обращаться за правовой защитой именно к Вам! С уважением, Валентина П.',
            swiperLink:'https://uslugi.yandex.ru/profile/EvgeniyaRyabova-335776?msp=no&occupationId=%2Furisty&specId=%2Furisty%2Ftrudovye-spory',
          },
          slideThree:{
            swiperTitle:'Отзыв на платформе Профи.ру',
            swiperName:'Марина',
            swiperImg:require('../../public/img/profi.svg'),
            swiperDesc:'Составляли договор оферту. Очень понравилось, что проговорили простым языком все непонятные для меня вопросы, сомнения и опасения. Евгения была в теме всех нюансов конкретно моего направления деятельности, даже работы тильды как конструктора сайта. Спасибо за помощь!',
            swiperLink:'https://profi.ru/profile/RyabovaYI2/reviews/'
          },
          slideFour:{
            swiperTitle:'Отзыв на платформе Профи.ру',
            swiperName:'Максим',
            swiperImg:require('../../public/img/profi.svg'),
            swiperDesc:'Несмотря на специфику запроса, Евгения успешно справилась с заказом! Дала сопутствующую информацию, детально ответила на возникшие вопросы. Приятна в коммуникациях и внимательна к деталям, это очень ценно! Благодарю! Желаю успехов и процветания!',
            swiperLink:'https://profi.ru/profile/RyabovaYI2/reviews/'
          },
          slideFive:{
            swiperTitle:'Отзыв на платформе YouDo',
            swiperName:'Артем А.',
            swiperImg:require('../../public/img/youdoLogo.svg'),
            swiperDesc:'Отличный исполнитель. Спасибо огромное Евгении за консультацию и помощь в оформлении документов. Евгения приложила максимум усилий и очень детально изучила вопрос. Всегда была на связи. Ну и конечно же порадовала оперативность выполнения. Однозначно рекомендую!',
            swiperLink:'https://youdo.com/u10127547'
          },
          slide6:{
            swiperTitle:'Отзыв на платформе Harant.ru',
            swiperName:'Зара',
            swiperImg:require('../assets/img/logo-harant.svg'),
            swiperDesc:'Получила четкий ответ! Спасибо!',
            swiperLink:'https://harant.ru/lawyers/sevastopol/ryabova-evgeniya-igorevna/',
          },
        }
      }
    },
  };
</script>

<style>
.otziv-swiper{
  padding: 1rem 0;
}
.swiper-slide{
  align-self: center;
}
.swiper-wrapper{
  padding: 2rem 0
}
.swiper-slide__wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  box-shadow: var(--box-shadow);
  width: 80%;
  margin: auto;
  padding: 2rem;
}
.slider-name{
  font-size: 1.2rem;
  font-family: 'Ubuntu',sans-serif;
  padding: 2rem 0 0;
}
.slide-img{
  height: 75px;
  width: 75px;
  border-radius: 50%;
  object-fit: contain;
  padding: 7px;
  box-shadow: 1px 1px 5px rgb(182, 182, 182);
  transition: box-shadow .5s;
}
.swiper-slide__wrap img {
  box-shadow: 0 0 5px rgb(206, 206, 206);
}
.swiper-slide__wrap img:hover{
  box-shadow: 1px 1px 15px rgb(143, 143, 143);
}
.swiper-slide__wrap img:active{
  box-shadow: 1px 1px 3px rgb(143, 143, 143);
}
.slide-desc{
  padding: 2rem;
  quotes: '„' '“';
  position: relative;
  font-size: 1.1rem;
}
.slide-title{
  padding-top: 2rem;
}
.slide-desc q:before {
  content: close-quote;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size:1.8rem;
}
.slide-desc q:after {
  content: open-quote;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.8rem;
}
.slide-link{
  padding: 1rem;
}
.swiper-button-prev{
  left: 4rem;
  color: white;
  text-shadow: 0 0 1px black;
  
}
.swiper-button-next{
  right: 4rem;
  color: white;
  text-shadow: 0 0 1px black;
}
.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 2rem;
}

.swiper-button-prev:hover, .swiper-button-next:hover {
  transform: scale(1.1);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.swiper-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet-active{
  background: white;
  box-shadow: var(--box-shadow);
  height: 10px;
  width: 10px;
}

@media (max-width:640px) {
  .otziv-swiper{
    padding: 0;
  }
  .swiper-slide__wrap{
    padding: 1rem;
    width: 90%;
  }
  .slider-name{
  font-size: 1.3rem;
  padding: 1rem;
  line-height: 1;
  text-align: center;
  }
  .slide-title{
    text-align: center;
    font-size: 1rem;
  }
  .slide-link{
    color: black;
  }
  .swiper-button-next, .swiper-button-prev{
    opacity: 0;
  }
}
</style>