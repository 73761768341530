<template>
  <div class="service-card-blok-wrap">
  <div class="service-block">
    <ServiceCard 
    v-for="item in serviceCard"
    :key="item"
    :card__title="item.card__title"
    :card__subtitle="item.card__subtitle"
    :card__desc="item.card__desc"
    />
  </div>
  <div class="best-price">
    <h3>Акция месяца</h3>
    <a class="about-btn" href="https://utrud.tilda.ws" target="_blank" rel="noopener noreferrer">Посмотреть акции</a>
  </div>
  </div>
</template>

<script>
import ServiceCard from './ServiceCard.vue'

export default {
    name:'ServiceBlock',
    components:{
      ServiceCard
    },
    data(){
      return{
        serviceCard:{
          employer:{
          card__title:'Работадателю',
          card__subtitle:'Услуги работадателю',
          card__desc:'Трудовые договоры, договоры ГПХ,должностные инструкции, увольнения, споры, судебное представительство.'
          },
        worker:{
          card__title:'Работнику',
          card__subtitle:'Услуги работнику',
          card__desc:'Консультация по трудовому праву. Составление исков жалоб, заявлений. Судебное представительство.'
          },
        samozan:{
          card__title:'Самозанятым',
          card__subtitle:'Услуги самозанятым',
          card__desc:'Консультация по правовым аспектам. Проверка и составление гражданско-правовых (ГПХ) договоров.'
          }
        }
      }
    }
}
</script>

<style>
.service-card-blok-wrap{
  padding: 5rem 0;
}
.service-block{
  display: flex;
  justify-content: space-around;
  width: 86%;
  margin: auto;
  font-size: 1rem;
}
.best-price h3{
  padding: 2rem;
  font-size: 2rem;
}
.best-price{
  margin: auto;
  max-width: 480px;
  text-align: center;
  padding: 3rem 0 0;
}
@media (max-width:640px) {
  .service-card-blok-wrap{
    padding: calc(3rem - 10px) 0;
  }
}
</style>