<template>
    <div v-if="show" class="modal-form" @click.self="closeModal">
        <div class="modal-form-content animate__animated animate__flipInX">
            <h2 class="modal-form__title">Свяжитесь с нами удобным вам способом</h2>
            <div class="line-about line-modal"></div>
            <div class="modal-form__mesenger">
                <a v-for="(item, index) in socialIcon" :key="index" :href="item.href">
                    <font-awesome-icon :icon="item.link" :alt="item"/>
                </a>
            </div>
            <div class="modal-form__form">
                <BottomForm/>
            </div>
        </div>
    </div>
</template>
 
<script>
import BottomForm from '../components/Bottom-Form.vue'
export default {
name: "ModalForm",
components:{BottomForm},
data(){
    return{
        socialIcon:{
            whatsapp:{
                href:"https://wa.me/79787045157",
                link:"fa-brands fa-whatsapp"
            },
            telegram:{
                href:"https://t.me/havanka",
                link:"fa-brands fa-telegram",
            },
            viber:{
                href:"viber://chat?number=79787045157",
                link:"fa-brands fa-viber"
            },
            mail:{
                href:"mailto:i@eryabova.ru",
                link:"fa-solid fa-envelope"
            }
        },
    }
},
props:{
    show:{
        type:Boolean,
        default:false
    }
},
methods:{
    closeModal: function () {
      this.$emit('update:show',false);
      document.body.style.overflow=''
    },
}
}
</script>
 
<style scoped lang="css">
.modal-form {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(181, 181, 181, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.modal-form-content{
    background: whitesmoke;
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.modal-form__title{
    text-align: center;
    font-weight: 300;
    padding: 1rem;
}
.line-modal{
    padding: 0;
    margin: 0 auto;
}
.modal-form__mesenger{
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
}

.modal-form__mesenger a {
    font-size: 3rem;
    transition: transform .3s;
    color: rgba(0, 0, 0, 0.7);
}

.modal-form__mesenger a:hover{
    transform: scale(1.1);
    color: rgba(0, 0, 0, 0.9);
}

.modal-form__form > .bottom-form{
    margin: 0;
    padding: 0;
    background: whitesmoke;
}

@media (max-width:640px) {
    .modal-form-content{
        margin: 0 5px;
    }
    .modal-form__title{
        font-size: 1.3rem;
        line-height: normal;
    }
}
</style>